import logo from "./logo.svg";

import feather from "feather-icons";
import Footer from "./footer";
import Sidebar from "./Sidebar";

import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import Login from "./login";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

import { Doughnut } from "react-chartjs-2";

import { Link } from "react-router-dom";

import favicon from "./assets/theme_asset/logo/favicon.png";

import logo1 from "./assets/theme_asset/logo/logo.png";

import logo2 from "./assets/theme_asset/svg/logo1.svg";

import auth_bg from "./assets/theme_asset/background/auth-bg.jpg";

import arrow from "./assets/theme_asset/svg/arrow.svg";

import arrow_down_right from "./assets/theme_asset/svg/arrow-down-right.svg";
import arrow_up_right from "./assets/theme_asset/svg/arrow-up-right.svg";

import p1 from "./assets/theme_asset/person/p1.png";
import p2 from "./assets/theme_asset/person/p2.png";
import p3 from "./assets/theme_asset/person/p3.png";
import p4 from "./assets/theme_asset/person/p4.png";
import p5 from "./assets/theme_asset/person/p5.png";
import ellipse from "./assets/theme_asset/svg/ellipse.svg";

import a1 from "./assets/theme_asset/svg/1.svg";
import a2 from "./assets/theme_asset/svg/2.svg";
import a3 from "./assets/theme_asset/svg/3.svg";
import a4 from "./assets/theme_asset/svg/4.svg";
import a5 from "./assets/theme_asset/svg/5.svg";

import a6 from "./assets/theme_asset/svg/6.svg";
import a7 from "./assets/theme_asset/svg/7.svg";
import a8 from "./assets/theme_asset/svg/8.svg";
import a9 from "./assets/theme_asset/svg/9.svg";
import a10 from "./assets/theme_asset/svg/10.svg";
import a11 from "./assets/theme_asset/svg/11.svg";

import lostchart from "./assets/theme_asset/svg/lost-chart.svg";
import successchart from "./assets/theme_asset/svg/success-chart.svg";
import bitcoins from "./assets/theme_asset/svg/bitcoins.svg";
import ethereum from "./assets/theme_asset/svg/ethereum.svg";
import tether from "./assets/theme_asset/svg/tether.svg";
import doge from "./assets/theme_asset/svg/doge.svg";
import litecoin from "./assets/theme_asset/svg/litecoin.svg";
import binance from "./assets/theme_asset/svg/binance.svg";
import banner1 from "./assets/theme_asset/banner/banner1.png";
import banner2 from "./assets/theme_asset/banner/banner2.png";

import card_chip from "./assets/theme_asset/svg/card-chip.svg";

import img1 from "./assets/theme_asset/1.jpg";

import contact from "./assets/theme_asset/svg/contact.svg";

import auth6 from "./assets/theme_asset/authentication/6.svg";

import img2 from "./assets/theme_asset/2.jpg";
import img3 from "./assets/theme_asset/3.jpg";
import img4 from "./assets/theme_asset/4.jpg";
import l49 from "./assets/theme_asset/logo/48.png";

import search from "./assets/search (1).png";

import "./App.css";

import scan from "./assets/theme_asset/svg/scan.svg";
import { useEffect } from "react";
import { Bar } from "react-chartjs-2";

import Chart from "chart.js/auto";

import Modal from "./modal";
import SkeletonLoader from "./SkeletonLoader";

function App() {
  const navigate = useNavigate();

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const apiImageUrl = process.env.REACT_APP_IMAGE_BASE_URL;

  // Define the state to track the active time period

  const [showModal, setShowModal] = useState(false);

  const fetchData = async () => {
    try {
      const id = localStorage.getItem("id");
      const response = await axios.get(`${apiBaseUrl}/homepageapi/${id}`);
      setData(response.data);
      // console.log(response.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const copyToClipboard = () => {
    const userEmail = data.user.email; // Replace this with the actual user's email
    const textToCopy = `https://www.app.smartfutureway.in/register?email=${userEmail}&side=left`;

    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          alert("Copied to clipboard!");
        })
        .catch((error) => console.error("Could not copy text: ", error));
    } else {
      // Fallback for browsers that don't support clipboard API
      const textField = document.createElement("textarea");
      textField.innerText = textToCopy;
      document.body.appendChild(textField);
      textField.select();
      document.execCommand("copy");
      textField.remove();
      alert("Copied to clipboard!");
    }
  };

  const copyToClipboard1 = () => {
    const userEmail = data.user.email; // Replace this with the actual user's email
    const textToCopy = `https://www.app.smartfutureway.in/register?email=${userEmail}&side=right`;

    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          alert("Copied to clipboard!");
        })
        .catch((error) => console.error("Could not copy text: ", error));
    } else {
      // Fallback for browsers that don't support clipboard API
      const textField = document.createElement("textarea");
      textField.innerText = textToCopy;
      document.body.appendChild(textField);
      textField.select();
      document.execCommand("copy");
      textField.remove();
      alert("Copied to clipboard!");
    }
  };
  const [modalMessage, setModalMessage] = useState(null);

  useEffect(() => {
    // Replace icons after component is mounted
    const storedMessage = localStorage.getItem("modalMesage3");

    if (storedMessage) {
      setModalMessage(JSON.parse(storedMessage));
      // Remove modal message from local storage after retrieving
      localStorage.removeItem("modalMessage3");
    }
    const id = localStorage.getItem("id");

    const token = localStorage.getItem("token");

    if (token == null) {
      navigate("/login");
    } else {
      navigate("/");
    }

    feather.replace();

    fetchData();
  }, []);

  if (loading) {
    <SkeletonLoader />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (data == null) {
    return <SkeletonLoader showHeader={true} showFooter={true} />;
  }

  const closeModal = () => {
    setShowModal(false);
  };

  // const registerLink = `https://www.app.smartfutureway.in/register?email=${data.user.email}&side=left`;
  // const registerLink1 = `https://www.app.smartfutureway.in/register?email=${data.user.email}&side=right`;
  const registerLink = `https://www.app.smartfutureway.in/register?email=${data.user.email}&side=left`;
  const registerLink1 = `https://www.app.smartfutureway.in/register?email=${data.user.email}&side=right`;

  // Function to handle click on a list item

  return (
    <div style={{ overflow: "hidden" }}>
      {showModal && <Modal message={modalMessage} closeModal={closeModal} />}

      <Sidebar />
      <>
        {/* side bar end */}
        {/* header start */}

        {/* header end */}
        {/* card start */}
        <section style={{ paddingTop: "0px" }}>
          <div className="custom-container">
            <div className="crypto-wallet-box">
              <div className="card-details">
                <div className="d-block w-75">
                  <h5 className="fw-semibold">Current Balance</h5>
                  <h2 className="mt-2">₹{data.wallet.activation_wallet}</h2>
                </div>
                <div className="price-difference">
                  <h6>ID: {data.user.email}</h6>
                  <h6>Status: <b>{data.user.is_active == 'active' ? 'Active' : 'Inactive'}</b></h6>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="custom-container">
          <div className="d-flex justify-content-evenly">
            <div
              className=""
              style={{
                backgroundColor: "white",
                margin: "10px 3px 10px 0",
                textAlign: "center",
                borderRadius: "10px",
                padding: "5px",
                width: "50%",
              }}
            >
              <a href={registerLink} className="mt-2 ">
                Left Refferal Link
              </a>
              <br />
              <div
                onClick={copyToClipboard}
                className="mt-2 mb-2"
                style={{
                  padding: "5px",
                  backgroundColor: "#6a30d8",
                  borderRadius: "25px",
                  color: "white",
                  textAlign: "center",
                }}
              >
                Click Here
              </div>
            </div>
            <div
              className=""
              style={{
                backgroundColor: "white",
                margin: "10px 0px 10px 3px",
                textAlign: "center",
                borderRadius: "10px",
                padding: "5px",
                width: "50%",
              }}
            >
              <a href={registerLink1} className="mt-2 ">
                Right Refferal Link
              </a>
              <br />
              <div
                onClick={copyToClipboard1}
                className="mt-2 mb-2"
                style={{
                  padding: "5px",
                  backgroundColor: "#6a30d8",
                  borderRadius: "25px",
                  color: "white",
                  textAlign: "center",
                }}
              >
                Click Here
              </div>
            </div>
          </div>
        </div>

        <section
          className="categories-section section-b-space"
          style={{ marginTop: "10px" }}
        >
          <div className="custom-container">
            <ul className="categories-list">
              <li>
                <Link to="/transfer">
                  <div className="categories-box">
                    <i className="categories-icon" data-feather="arrow-up" />
                  </div>
                  <h5 className="mt-2 text-center">Transfer</h5>
                </Link>
              </li>
              <li>
                <Link to="/deposite">
                  <div className="categories-box">
                    <i className="categories-icon" data-feather="arrow-down" />
                  </div>
                  <h5 className="mt-2 text-center">Deposite</h5>
                </Link>
              </li>
              <li>
                <Link to="/activation">
                  <div className="categories-box">
                    <i className="categories-icon" data-feather="repeat" />
                  </div>
                  <h5 className="mt-2 text-center">Activate</h5>
                </Link>
              </li>
              <li>
                <Link to="/withdraw">
                  <div className="categories-box">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="#622cfd"
                        d="M2 20h20v2H2zm2-8h2v7H4zm5 0h2v7H9zm4 0h2v7h-2zm5 0h2v7h-2zM2 7l10-5l10 5v4H2zm2 1.236V9h16v-.764l-8-4zM12 8a1 1 0 1 1 0-2a1 1 0 0 1 0 2"
                      />
                    </svg>
                  </div>
                  <h5 className="mt-2 text-center">Withdraw</h5>
                </Link>
              </li>
            </ul>
          </div>
        </section>
        {/* categories section end */}
        {/* my-portfolio section starts */}
        <section>
          <div className="custom-container">
            <div className="title">
              <h2>My Portfolio</h2>
              <h3
                style={{
                  backgroundColor: "white",
                  padding: "0 10px",
                  alignContent: "center",
                  borderRadius: "10px",
                }}
              >
                Available Coin: {data.wallet.total_deposit}
              </h3>
            </div>

            <div className="d-flex justify-content-evenly mb-2">
              <div
                className="d-flex align-items-center "
                style={{
                  backgroundColor: "white",
                  padding: "5px",
                  borderRadius: "10px",
                  width: "50%",
                  justifyContent: "start",
                }}
              >
                <div className="portfolio-img" style={{ marginRight: "10%" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="35"
                    height="35"
                    viewBox="0 0 48 48"
                  >
                    <defs>
                      <mask id="ipSEqualRatio0">
                        <g fill="none">
                          <rect
                            width="36"
                            height="36"
                            x="6"
                            y="6"
                            fill="#fff"
                            stroke="#fff"
                            stroke-linejoin="round"
                            stroke-width="4"
                            rx="3"
                          />
                          <path
                            fill="#000"
                            fill-rule="evenodd"
                            d="M24 22.5a2.5 2.5 0 1 0 0-5a2.5 2.5 0 0 0 0 5m0 8a2.5 2.5 0 1 0 0-5a2.5 2.5 0 0 0 0 5"
                            clip-rule="evenodd"
                          />
                          <path
                            stroke="#000"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="4"
                            d="M15.5 17v14m17-14v14"
                          />
                        </g>
                      </mask>
                    </defs>
                    <path
                      fill="#8361df"
                      d="M0 0h48v48H0z"
                      mask="url(#ipSEqualRatio0)"
                    />
                  </svg>
                </div>
                <div className="portfolio-details">
                  <div>
                    <h6>Matching Income</h6>
                    <h6
                      className=" fw-normal"
                      style={{
                        textAlign: "center",
                        marginTop: "5px",
                        color: "black",
                      }}
                    >
                      ₹{data.wallet.matching_income}
                    </h6>
                  </div>
                </div>
              </div>
              <div
                className="d-flex align-items-center"
                style={{
                  backgroundColor: "white",
                  padding: "5px",
                  borderRadius: "10px",
                  marginLeft: "5px",
                  width: "50%",
                  justifyContent: "start",
                }}
              >
                <div className="portfolio-img" style={{ marginRight: "15%" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="35"
                    height="35"
                    viewBox="0 0 48 48"
                  >
                    <defs>
                      <mask id="ipSLevel0">
                        <g
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="4"
                        >
                          <path
                            fill="#fff"
                            stroke="#fff"
                            d="M24 42L4 18.5L9.695 6h28.61L44 18.5z"
                          />
                          <path stroke="#000" d="m32 18l-8 9l-8-9" />
                        </g>
                      </mask>
                    </defs>
                    <path
                      fill="#8361df"
                      d="M0 0h48v48H0z"
                      mask="url(#ipSLevel0)"
                    />
                  </svg>
                </div>
                <div className="portfolio-details">
                  <div>
                    <h6>Level Income</h6>
                    <h6
                      className=" fw-normal"
                      style={{
                        textAlign: "center",
                        marginTop: "5px",
                        color: "black",
                      }}
                    >
                      ₹{data.wallet.salary_income}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-evenly mb-2">
              <div
                className="d-flex align-items-center "
                style={{
                  backgroundColor: "white",
                  padding: "5px",
                  borderRadius: "10px",
                  width: "50%",
                  justifyContent: "start",
                }}
              >
                <div className="portfolio-img" style={{ marginRight: "15%" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="35"
                    height="35"
                    viewBox="0 0 24 24"
                  >
                    <g fill="#8361df">
                      <path d="M12 7.5a2.25 2.25 0 1 0 0 4.5a2.25 2.25 0 0 0 0-4.5" />
                      <path
                        fill-rule="evenodd"
                        d="M1.5 4.875C1.5 3.839 2.34 3 3.375 3h17.25c1.035 0 1.875.84 1.875 1.875v9.75c0 1.036-.84 1.875-1.875 1.875H3.375A1.875 1.875 0 0 1 1.5 14.625zM8.25 9.75a3.75 3.75 0 1 1 7.5 0a3.75 3.75 0 0 1-7.5 0M18.75 9a.75.75 0 0 0-.75.75v.008c0 .414.336.75.75.75h.008a.75.75 0 0 0 .75-.75V9.75a.75.75 0 0 0-.75-.75zM4.5 9.75A.75.75 0 0 1 5.25 9h.008a.75.75 0 0 1 .75.75v.008a.75.75 0 0 1-.75.75H5.25a.75.75 0 0 1-.75-.75z"
                        clip-rule="evenodd"
                      />
                      <path d="M2.25 18a.75.75 0 0 0 0 1.5c5.4 0 10.63.722 15.6 2.075c1.19.324 2.4-.558 2.4-1.82V18.75a.75.75 0 0 0-.75-.75z" />
                    </g>
                  </svg>
                </div>
                <div className="portfolio-details">
                  <div>
                    <div style={{fontSize:'12px'}}>Direct Income(10%)</div>
                    <h6
                      className=" fw-normal"
                      style={{
                        textAlign: "center",
                        marginTop: "5px",
                        color: "black",
                      }}
                    >
                      ₹{data.wallet.direct}
                    </h6>
                  </div>
                </div>
              </div>
              <div
                className="d-flex align-items-center"
                style={{
                  backgroundColor: "white",
                  padding: "5px",
                  borderRadius: "10px",
                  marginLeft: "5px",
                  width: "50%",
                  justifyContent: "start",
                }}
              >
                <div className="portfolio-img" style={{ marginRight: "10%" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="35"
                    height="35"
                    viewBox="0 0 48 48"
                  >
                    <defs>
                      <mask id="ipSBankCard0">
                        <g fill="none" stroke-linejoin="round" stroke-width="4">
                          <path
                            fill="#fff"
                            stroke="#fff"
                            d="M4 10a2 2 0 0 1 2-2h36a2 2 0 0 1 2 2v28a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2z"
                          />
                          <path
                            stroke="#000"
                            stroke-linecap="square"
                            d="M4 16h40"
                          />
                          <path
                            stroke="#000"
                            stroke-linecap="round"
                            d="M27 32h9"
                          />
                          <path
                            stroke="#fff"
                            stroke-linecap="round"
                            d="M44 10v16M4 10v16"
                          />
                        </g>
                      </mask>
                    </defs>
                    <path
                      fill="#8361df"
                      d="M0 0h48v48H0z"
                      mask="url(#ipSBankCard0)"
                    />
                  </svg>
                </div>
                <div className="portfolio-details">
                  <div>
                    <h6>ROI Income</h6>
                    <h6
                      className=" fw-normal"
                      style={{
                        textAlign: "center",
                        marginTop: "5px",
                        color: "black",
                      }}
                    >
                      ₹{data.wallet.roi_income}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-evenly">
              <div
                className="d-flex align-items-center "
                style={{
                  backgroundColor: "white",
                  padding: "5px",
                  borderRadius: "10px",
                  width: "80%",
                  justifyContent: "start",
                }}
              >
                <div className="portfolio-img" style={{ marginRight: "15%" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="35"
                    height="35"
                    viewBox="0 0 24 24"
                  >
                    <g fill="#8361df">
                      <path d="M12 7.5a2.25 2.25 0 1 0 0 4.5a2.25 2.25 0 0 0 0-4.5" />
                      <path
                        fill-rule="evenodd"
                        d="M1.5 4.875C1.5 3.839 2.34 3 3.375 3h17.25c1.035 0 1.875.84 1.875 1.875v9.75c0 1.036-.84 1.875-1.875 1.875H3.375A1.875 1.875 0 0 1 1.5 14.625zM8.25 9.75a3.75 3.75 0 1 1 7.5 0a3.75 3.75 0 0 1-7.5 0M18.75 9a.75.75 0 0 0-.75.75v.008c0 .414.336.75.75.75h.008a.75.75 0 0 0 .75-.75V9.75a.75.75 0 0 0-.75-.75zM4.5 9.75A.75.75 0 0 1 5.25 9h.008a.75.75 0 0 1 .75.75v.008a.75.75 0 0 1-.75.75H5.25a.75.75 0 0 1-.75-.75z"
                        clip-rule="evenodd"
                      />
                      <path d="M2.25 18a.75.75 0 0 0 0 1.5c5.4 0 10.63.722 15.6 2.075c1.19.324 2.4-.558 2.4-1.82V18.75a.75.75 0 0 0-.75-.75z" />
                    </g>
                  </svg>
                </div>
                <div className="portfolio-details">
                  <div>
                    <h6>Direct Sponcer Income(5%)</h6>
                    <h6
                      className=" fw-normal"
                      style={{
                        textAlign: "center",
                        marginTop: "5px",
                        color: "black",
                      }}
                    >
                      ₹{data.wallet.direct_sponcer_income}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="custom-container">
            <div className="title">
              <h2>Daily Business</h2>
            </div>

            <div className="d-flex justify-content-evenly mb-2">
              <div
                className="d-flex align-items-center "
                style={{
                  backgroundColor: "white",
                  padding: "5px",
                  borderRadius: "10px",
                  width: "50%",
                  justifyContent: "start",
                }}
              >
                <div className="portfolio-img" style={{ marginRight: "10%" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="35"
                    height="35"
                    viewBox="0 0 24 24"
                  >
                    <g fill="#8361df">
                      <path d="M12 7.5a2.25 2.25 0 1 0 0 4.5a2.25 2.25 0 0 0 0-4.5" />
                      <path
                        fill-rule="evenodd"
                        d="M1.5 4.875C1.5 3.839 2.34 3 3.375 3h17.25c1.035 0 1.875.84 1.875 1.875v9.75c0 1.036-.84 1.875-1.875 1.875H3.375A1.875 1.875 0 0 1 1.5 14.625zM8.25 9.75a3.75 3.75 0 1 1 7.5 0a3.75 3.75 0 0 1-7.5 0M18.75 9a.75.75 0 0 0-.75.75v.008c0 .414.336.75.75.75h.008a.75.75 0 0 0 .75-.75V9.75a.75.75 0 0 0-.75-.75zM4.5 9.75A.75.75 0 0 1 5.25 9h.008a.75.75 0 0 1 .75.75v.008a.75.75 0 0 1-.75.75H5.25a.75.75 0 0 1-.75-.75z"
                        clip-rule="evenodd"
                      />
                      <path d="M2.25 18a.75.75 0 0 0 0 1.5c5.4 0 10.63.722 15.6 2.075c1.19.324 2.4-.558 2.4-1.82V18.75a.75.75 0 0 0-.75-.75z" />
                    </g>
                  </svg>
                </div>
                <div className="portfolio-details">
                  <div>
                    <div style={{ fontSize: "12px" }}>Daily Left Business</div>
                    <h6
                      className=" fw-normal"
                      style={{
                        textAlign: "center",
                        marginTop: "5px",
                        color: "black",
                      }}
                    >
                      ₹{data.user.d_left_business ? data.user.d_left_business : "0"}
                    </h6>
                  </div>
                </div>
              </div>
              <div
                className="d-flex align-items-center"
                style={{
                  backgroundColor: "white",
                  padding: "5px",
                  borderRadius: "10px",
                  marginLeft: "5px",
                  width: "50%",
                  justifyContent: "start",
                }}
              >
                <div className="portfolio-img" style={{ marginRight: "15%" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="35"
                    height="35"
                    viewBox="0 0 24 24"
                  >
                    <g fill="#8361df">
                      <path d="M12 7.5a2.25 2.25 0 1 0 0 4.5a2.25 2.25 0 0 0 0-4.5" />
                      <path
                        fill-rule="evenodd"
                        d="M1.5 4.875C1.5 3.839 2.34 3 3.375 3h17.25c1.035 0 1.875.84 1.875 1.875v9.75c0 1.036-.84 1.875-1.875 1.875H3.375A1.875 1.875 0 0 1 1.5 14.625zM8.25 9.75a3.75 3.75 0 1 1 7.5 0a3.75 3.75 0 0 1-7.5 0M18.75 9a.75.75 0 0 0-.75.75v.008c0 .414.336.75.75.75h.008a.75.75 0 0 0 .75-.75V9.75a.75.75 0 0 0-.75-.75zM4.5 9.75A.75.75 0 0 1 5.25 9h.008a.75.75 0 0 1 .75.75v.008a.75.75 0 0 1-.75.75H5.25a.75.75 0 0 1-.75-.75z"
                        clip-rule="evenodd"
                      />
                      <path d="M2.25 18a.75.75 0 0 0 0 1.5c5.4 0 10.63.722 15.6 2.075c1.19.324 2.4-.558 2.4-1.82V18.75a.75.75 0 0 0-.75-.75z" />
                    </g>
                  </svg>
                </div>
                <div className="portfolio-details">
                  <div>
                    <div style={{ fontSize: "12px" ,marginLeft:'-5px'}}>Daily Right Business</div>
                    <h6
                      className=" fw-normal"
                      style={{
                        textAlign: "center",
                        marginTop: "5px",
                        color: "black",
                      }}
                    >
                      ₹
                      {data.user.d_right_business
                        ? data.user.d_right_business
                        : "0"}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-evenly mb-2">
              <div
                className="d-flex align-items-center "
                style={{
                  backgroundColor: "white",
                  padding: "5px",
                  borderRadius: "10px",
                  width: "50%",
                  justifyContent: "start",
                }}
              >
                <div className="portfolio-img" style={{ marginRight: "15%" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="35"
                    height="35"
                    viewBox="0 0 48 48"
                  >
                    <defs>
                      <mask id="ipSEqualRatio0">
                        <g fill="none">
                          <rect
                            width="36"
                            height="36"
                            x="6"
                            y="6"
                            fill="#fff"
                            stroke="#fff"
                            stroke-linejoin="round"
                            stroke-width="4"
                            rx="3"
                          />
                          <path
                            fill="#000"
                            fill-rule="evenodd"
                            d="M24 22.5a2.5 2.5 0 1 0 0-5a2.5 2.5 0 0 0 0 5m0 8a2.5 2.5 0 1 0 0-5a2.5 2.5 0 0 0 0 5"
                            clip-rule="evenodd"
                          />
                          <path
                            stroke="#000"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="4"
                            d="M15.5 17v14m17-14v14"
                          />
                        </g>
                      </mask>
                    </defs>
                    <path
                      fill="#8361df"
                      d="M0 0h48v48H0z"
                      mask="url(#ipSEqualRatio0)"
                    />
                  </svg>
                </div>
                <div className="portfolio-details">
                  <div>
                    <h6>Daily Left BV</h6>
                    <h6
                      className=" fw-normal"
                      style={{
                        textAlign: "center",
                        marginTop: "5px",
                        color: "black",
                      }}
                    >
                      {data.user.daily_left_count}
                    </h6>
                  </div>
                </div>
              </div>
              <div
                className="d-flex align-items-center"
                style={{
                  backgroundColor: "white",
                  padding: "5px",
                  borderRadius: "10px",
                  marginLeft: "5px",
                  width: "50%",
                  justifyContent: "start",
                }}
              >
                <div className="portfolio-img" style={{ marginRight: "10%" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="35"
                    height="35"
                    viewBox="0 0 48 48"
                  >
                    <defs>
                      <mask id="ipSEqualRatio0">
                        <g fill="none">
                          <rect
                            width="36"
                            height="36"
                            x="6"
                            y="6"
                            fill="#fff"
                            stroke="#fff"
                            stroke-linejoin="round"
                            stroke-width="4"
                            rx="3"
                          />
                          <path
                            fill="#000"
                            fill-rule="evenodd"
                            d="M24 22.5a2.5 2.5 0 1 0 0-5a2.5 2.5 0 0 0 0 5m0 8a2.5 2.5 0 1 0 0-5a2.5 2.5 0 0 0 0 5"
                            clip-rule="evenodd"
                          />
                          <path
                            stroke="#000"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="4"
                            d="M15.5 17v14m17-14v14"
                          />
                        </g>
                      </mask>
                    </defs>
                    <path
                      fill="#8361df"
                      d="M0 0h48v48H0z"
                      mask="url(#ipSEqualRatio0)"
                    />
                  </svg>
                </div>
                <div className="portfolio-details">
                  <div>
                    <h6>Daily Right BV</h6>
                    <h6
                      className=" fw-normal"
                      style={{
                        textAlign: "center",
                        marginTop: "5px",
                        color: "black",
                      }}
                    >
                      {data.user.daily_right_count}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="d-flex justify-content-evenly">
     
     <div className="d-flex align-items-center " style={{backgroundColor:'white',padding:'5px',borderRadius:'10px',width:'80%',justifyContent:'start'}}>
                   <div className="portfolio-img" style={{marginRight:'15%'}}>
                   <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 24 24">
	<g fill="#8361df">
		<path d="M12 7.5a2.25 2.25 0 1 0 0 4.5a2.25 2.25 0 0 0 0-4.5" />
		<path fill-rule="evenodd" d="M1.5 4.875C1.5 3.839 2.34 3 3.375 3h17.25c1.035 0 1.875.84 1.875 1.875v9.75c0 1.036-.84 1.875-1.875 1.875H3.375A1.875 1.875 0 0 1 1.5 14.625zM8.25 9.75a3.75 3.75 0 1 1 7.5 0a3.75 3.75 0 0 1-7.5 0M18.75 9a.75.75 0 0 0-.75.75v.008c0 .414.336.75.75.75h.008a.75.75 0 0 0 .75-.75V9.75a.75.75 0 0 0-.75-.75zM4.5 9.75A.75.75 0 0 1 5.25 9h.008a.75.75 0 0 1 .75.75v.008a.75.75 0 0 1-.75.75H5.25a.75.75 0 0 1-.75-.75z" clip-rule="evenodd" />
		<path d="M2.25 18a.75.75 0 0 0 0 1.5c5.4 0 10.63.722 15.6 2.075c1.19.324 2.4-.558 2.4-1.82V18.75a.75.75 0 0 0-.75-.75z" />
	</g>
</svg>
                   </div>
                   <div className="portfolio-details">
                     <div>
                       <h6>Direct Sponcer Income(5%)</h6>
                       <h6 className=" fw-normal" style={{textAlign:'center',marginTop:'5px',color:'black'}}>₹{data.wallet.direct_sponcer_income}</h6>
                     </div>
                    
                   </div>
                 </div>
                
   
   </div> */}
          </div>
        </section>
        <section>
          <div className="custom-container">
            <div className="title">
              <h2>Total Business</h2>
            </div>

            <div className="d-flex justify-content-evenly mb-2">
              <div
                className="d-flex align-items-center "
                style={{
                  backgroundColor: "white",
                  padding: "5px",
                  borderRadius: "10px",
                  width: "50%",
                  justifyContent: "start",
                }}
              >
                <div className="portfolio-img" style={{ marginRight: "10%" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="35"
                    height="35"
                    viewBox="0 0 24 24"
                  >
                    <g fill="#8361df">
                      <path d="M12 7.5a2.25 2.25 0 1 0 0 4.5a2.25 2.25 0 0 0 0-4.5" />
                      <path
                        fill-rule="evenodd"
                        d="M1.5 4.875C1.5 3.839 2.34 3 3.375 3h17.25c1.035 0 1.875.84 1.875 1.875v9.75c0 1.036-.84 1.875-1.875 1.875H3.375A1.875 1.875 0 0 1 1.5 14.625zM8.25 9.75a3.75 3.75 0 1 1 7.5 0a3.75 3.75 0 0 1-7.5 0M18.75 9a.75.75 0 0 0-.75.75v.008c0 .414.336.75.75.75h.008a.75.75 0 0 0 .75-.75V9.75a.75.75 0 0 0-.75-.75zM4.5 9.75A.75.75 0 0 1 5.25 9h.008a.75.75 0 0 1 .75.75v.008a.75.75 0 0 1-.75.75H5.25a.75.75 0 0 1-.75-.75z"
                        clip-rule="evenodd"
                      />
                      <path d="M2.25 18a.75.75 0 0 0 0 1.5c5.4 0 10.63.722 15.6 2.075c1.19.324 2.4-.558 2.4-1.82V18.75a.75.75 0 0 0-.75-.75z" />
                    </g>
                  </svg>
                </div>
                <div className="portfolio-details">
                  <div>
                    <div style={{ fontSize: "12px" }}>Total Left Business</div>
                    <h6
                      className=" fw-normal"
                      style={{
                        textAlign: "center",
                        marginTop: "5px",
                        color: "black",
                      }}
                    >
                      ₹{data.user.left_business ? data.user.left_business : "0"}
                    </h6>
                  </div>
                </div>
              </div>
              <div
                className="d-flex align-items-center"
                style={{
                  backgroundColor: "white",
                  padding: "5px",
                  borderRadius: "10px",
                  marginLeft: "5px",
                  width: "50%",
                  justifyContent: "start",
                }}
              >
                <div className="portfolio-img" style={{ marginRight: "15%" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="35"
                    height="35"
                    viewBox="0 0 24 24"
                  >
                    <g fill="#8361df">
                      <path d="M12 7.5a2.25 2.25 0 1 0 0 4.5a2.25 2.25 0 0 0 0-4.5" />
                      <path
                        fill-rule="evenodd"
                        d="M1.5 4.875C1.5 3.839 2.34 3 3.375 3h17.25c1.035 0 1.875.84 1.875 1.875v9.75c0 1.036-.84 1.875-1.875 1.875H3.375A1.875 1.875 0 0 1 1.5 14.625zM8.25 9.75a3.75 3.75 0 1 1 7.5 0a3.75 3.75 0 0 1-7.5 0M18.75 9a.75.75 0 0 0-.75.75v.008c0 .414.336.75.75.75h.008a.75.75 0 0 0 .75-.75V9.75a.75.75 0 0 0-.75-.75zM4.5 9.75A.75.75 0 0 1 5.25 9h.008a.75.75 0 0 1 .75.75v.008a.75.75 0 0 1-.75.75H5.25a.75.75 0 0 1-.75-.75z"
                        clip-rule="evenodd"
                      />
                      <path d="M2.25 18a.75.75 0 0 0 0 1.5c5.4 0 10.63.722 15.6 2.075c1.19.324 2.4-.558 2.4-1.82V18.75a.75.75 0 0 0-.75-.75z" />
                    </g>
                  </svg>
                </div>
                <div className="portfolio-details">
                  <div>
                    <div style={{ fontSize: "12px" }}>Total Right Business</div>
                    <h6
                      className=" fw-normal"
                      style={{
                        textAlign: "center",
                        marginTop: "5px",
                        color: "black",
                      }}
                    >
                      ₹
                      {data.user.right_business
                        ? data.user.right_business
                        : "0"}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-evenly mb-2">
              <div
                className="d-flex align-items-center "
                style={{
                  backgroundColor: "white",
                  padding: "5px",
                  borderRadius: "10px",
                  width: "50%",
                  justifyContent: "start",
                }}
              >
                <div className="portfolio-img" style={{ marginRight: "15%" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="35"
                    height="35"
                    viewBox="0 0 48 48"
                  >
                    <defs>
                      <mask id="ipSEqualRatio0">
                        <g fill="none">
                          <rect
                            width="36"
                            height="36"
                            x="6"
                            y="6"
                            fill="#fff"
                            stroke="#fff"
                            stroke-linejoin="round"
                            stroke-width="4"
                            rx="3"
                          />
                          <path
                            fill="#000"
                            fill-rule="evenodd"
                            d="M24 22.5a2.5 2.5 0 1 0 0-5a2.5 2.5 0 0 0 0 5m0 8a2.5 2.5 0 1 0 0-5a2.5 2.5 0 0 0 0 5"
                            clip-rule="evenodd"
                          />
                          <path
                            stroke="#000"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="4"
                            d="M15.5 17v14m17-14v14"
                          />
                        </g>
                      </mask>
                    </defs>
                    <path
                      fill="#8361df"
                      d="M0 0h48v48H0z"
                      mask="url(#ipSEqualRatio0)"
                    />
                  </svg>
                </div>
                <div className="portfolio-details">
                  <div>
                    <h6>Total Left BV</h6>
                    <h6
                      className=" fw-normal"
                      style={{
                        textAlign: "center",
                        marginTop: "5px",
                        color: "black",
                      }}
                    >
                      {data.user.left_bv}
                    </h6>
                  </div>
                </div>
              </div>
              <div
                className="d-flex align-items-center"
                style={{
                  backgroundColor: "white",
                  padding: "5px",
                  borderRadius: "10px",
                  marginLeft: "5px",
                  width: "50%",
                  justifyContent: "start",
                }}
              >
                <div className="portfolio-img" style={{ marginRight: "10%" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="35"
                    height="35"
                    viewBox="0 0 48 48"
                  >
                    <defs>
                      <mask id="ipSEqualRatio0">
                        <g fill="none">
                          <rect
                            width="36"
                            height="36"
                            x="6"
                            y="6"
                            fill="#fff"
                            stroke="#fff"
                            stroke-linejoin="round"
                            stroke-width="4"
                            rx="3"
                          />
                          <path
                            fill="#000"
                            fill-rule="evenodd"
                            d="M24 22.5a2.5 2.5 0 1 0 0-5a2.5 2.5 0 0 0 0 5m0 8a2.5 2.5 0 1 0 0-5a2.5 2.5 0 0 0 0 5"
                            clip-rule="evenodd"
                          />
                          <path
                            stroke="#000"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="4"
                            d="M15.5 17v14m17-14v14"
                          />
                        </g>
                      </mask>
                    </defs>
                    <path
                      fill="#8361df"
                      d="M0 0h48v48H0z"
                      mask="url(#ipSEqualRatio0)"
                    />
                  </svg>
                </div>
                <div className="portfolio-details">
                  <div>
                    <h6>Total Right BV</h6>
                    <h6
                      className=" fw-normal"
                      style={{
                        textAlign: "center",
                        marginTop: "5px",
                        color: "black",
                      }}
                    >
                      {data.user.right_bv}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="d-flex justify-content-evenly">
     
     <div className="d-flex align-items-center " style={{backgroundColor:'white',padding:'5px',borderRadius:'10px',width:'80%',justifyContent:'start'}}>
                   <div className="portfolio-img" style={{marginRight:'15%'}}>
                   <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 24 24">
	<g fill="#8361df">
		<path d="M12 7.5a2.25 2.25 0 1 0 0 4.5a2.25 2.25 0 0 0 0-4.5" />
		<path fill-rule="evenodd" d="M1.5 4.875C1.5 3.839 2.34 3 3.375 3h17.25c1.035 0 1.875.84 1.875 1.875v9.75c0 1.036-.84 1.875-1.875 1.875H3.375A1.875 1.875 0 0 1 1.5 14.625zM8.25 9.75a3.75 3.75 0 1 1 7.5 0a3.75 3.75 0 0 1-7.5 0M18.75 9a.75.75 0 0 0-.75.75v.008c0 .414.336.75.75.75h.008a.75.75 0 0 0 .75-.75V9.75a.75.75 0 0 0-.75-.75zM4.5 9.75A.75.75 0 0 1 5.25 9h.008a.75.75 0 0 1 .75.75v.008a.75.75 0 0 1-.75.75H5.25a.75.75 0 0 1-.75-.75z" clip-rule="evenodd" />
		<path d="M2.25 18a.75.75 0 0 0 0 1.5c5.4 0 10.63.722 15.6 2.075c1.19.324 2.4-.558 2.4-1.82V18.75a.75.75 0 0 0-.75-.75z" />
	</g>
</svg>
                   </div>
                   <div className="portfolio-details">
                     <div>
                       <h6>Direct Sponcer Income(5%)</h6>
                       <h6 className=" fw-normal" style={{textAlign:'center',marginTop:'5px',color:'black'}}>₹{data.wallet.direct_sponcer_income}</h6>
                     </div>
                    
                   </div>
                 </div>
                
   
   </div> */}
          </div>
        </section>
        {data &&
          data.latest_five_trans &&
          data.latest_five_trans.length > 0 && (
            <section>
              <div className="custom-container">
                <div className="title">
                  <h2>Latest Five Transaction</h2>
                </div>
                <div className="row ">
                  {data.latest_five_trans.map((item, index) => (
                    <div className="col-12 col-md-6 col-lg-4" key={index}>
                      <div className="transaction-box">
                        <a href="#" className="d-flex gap-3">
                          <div className="transaction-image color1">
                            <img
                              className="img-fluid icon"
                              src={bitcoins}
                              alt="bitcoins"
                            />
                          </div>
                          <div className="transaction-details">
                            <div className="transaction-name">
                              <h5>
                                {item.sender === data.user.email
                                  ? "Send To:"
                                  : "Received From:"}{" "}
                                {item.reciver === data.user.email
                                  ? "self"
                                  : item.reciver}
                              </h5>
                              <h3 className="dark-text">₹{item.amount}</h3>
                            </div>
                            <div className="d-flex justify-content-between">
                              <h5 className="light-text">{item.reason}</h5>
                              <h5 className="dark-text">
                                {new Date(item.created_at).toLocaleDateString(
                                  "en-GB",
                                  {
                                    day: "2-digit",
                                    month: "2-digit",
                                    year: "numeric",
                                  }
                                )}
                              </h5>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          )}
        {/* Transaction section end */}
        {/* banner section starts */}
        <section>
          <div className="custom-container">
            <div className="swiper-container">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <a href="#">
                    <img
                      className="img-fluid banner-img"
                      src={banner1}
                      alt="banner1"
                    />
                  </a>
                </div>
                <div className="swiper-slide">
                  <a href="#">
                    <img
                      className="img-fluid banner-img"
                      src={banner2}
                      alt="banner2"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* banner section end */}
        {/* news-update section starts */}
        <section className="section-b-space">
          <div className="custom-container">
            <div className="title">
              <h2>Information Bits</h2>
              <Link to="/blog">See all</Link>
            </div>
            <div className="row gy-3">
              {data.latest_content_bytes.map((content_byte, index) => (
                <div className="col-12">
                  <div className="news-update-box">
                    <div className="d-flex align-items-center gap-3">
                      <Link to="/blog">
                        <img
                          className="img-fluid news-update-image"
                          src={`${apiImageUrl}/blog_image/${content_byte.blog_image}`}
                          alt="img1"
                        />
                      </Link>
                      <div className="news-update-content">
                        <Link to={`/blog_details/${content_byte.id}`}>
                          <h3>{content_byte.blog_title}</h3>
                        </Link>
                        <div
                          className="news-writer"
                          style={{ marginTop: "5px" }}
                        >
                          <div className="d-flex ">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10"
                              height="10"
                              viewBox="0 0 20 20"
                            >
                              <path
                                fill="#000"
                                d="M5.673 0a.7.7 0 0 1 .7.7v1.309h7.517v-1.3a.7.7 0 0 1 1.4 0v1.3H18a2 2 0 0 1 2 1.999v13.993A2 2 0 0 1 18 20H2a2 2 0 0 1-2-1.999V4.008a2 2 0 0 1 2-1.999h2.973V.699a.7.7 0 0 1 .7-.699M1.4 7.742v10.259a.6.6 0 0 0 .6.6h16a.6.6 0 0 0 .6-.6V7.756zm5.267 6.877v1.666H5v-1.666zm4.166 0v1.666H9.167v-1.666zm4.167 0v1.666h-1.667v-1.666zm-8.333-3.977v1.666H5v-1.666zm4.166 0v1.666H9.167v-1.666zm4.167 0v1.666h-1.667v-1.666zM4.973 3.408H2a.6.6 0 0 0-.6.6v2.335l17.2.014V4.008a.6.6 0 0 0-.6-.6h-2.71v.929a.7.7 0 0 1-1.4 0v-.929H6.373v.92a.7.7 0 0 1-1.4 0z"
                              />
                            </svg>{" "}
                            <p className="fs-7" style={{ margin: "0 0 0 2px" }}>
                              {" "}
                              {content_byte.date}
                            </p>
                          </div>
                          <div className="d-flex">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10"
                              height="10"
                              viewBox="0 0 36 36"
                            >
                              <path
                                fill="#000"
                                d="M33.87 8.32L28 2.42a2.07 2.07 0 0 0-2.92 0L4.27 23.2l-1.9 8.2a2.06 2.06 0 0 0 2 2.5a2.14 2.14 0 0 0 .43 0l8.29-1.9l20.78-20.76a2.07 2.07 0 0 0 0-2.92M12.09 30.2l-7.77 1.63l1.77-7.62L21.66 8.7l6 6ZM29 13.25l-6-6l3.48-3.46l5.9 6Z"
                                class="clr-i-outline clr-i-outline-path-1"
                              />
                              <path fill="none" d="M0 0h36v36H0z" />
                            </svg>{" "}
                            <p className="fs-7" style={{ margin: "0 0 0 0" }}>
                              {" "}
                              {content_byte.author}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        {/* news-update section end */}
        {/* panel-space start */}
      </>

      <Footer />
    </div>
  );
}

export default App;
